export default {
  formatTotal(total) {
    return new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(total || 0)
  },

  formatDate(value) {
    if (!value) return value
    return new Intl.DateTimeFormat('en-MY', { dateStyle: 'short' }).format(new Date(value))
  },

  formatDateTime(value) {
    if (!value) return value
    return new Intl.DateTimeFormat('en-MY', {
      hourCycle: 'h12',
      timeStyle: 'short',
      dateStyle: 'short',
    }).format(new Date(value)).replace(/,/g, '')
  },

  formatTextToCapitalized(value, replacer = ' ') {
    if (!value) return ''
    const str = value.toString().toLowerCase()
    const arr = str.split(replacer)
    const capitalizedArray = []
    arr.forEach(word => {
      const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
      capitalizedArray.push(capitalized)
    })
    return capitalizedArray.join(' ')
  },

  customDateFormat(date) {
    const formatDate = new Date(date)
    const strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const d = formatDate.getDate()
    const m = strArray[formatDate.getMonth()]
    const y = formatDate.getFullYear()
    return `${d} ${m} ${y}`
  },

  formatAddresses(addresses) {
    if (addresses) {
      const formattedAddressesOption = []
      addresses.forEach(address => {
        const hasParent = formattedAddressesOption.some(mapAddress => mapAddress.type === 'parent' && mapAddress.area === address.area)
        if (!hasParent) {
          formattedAddressesOption.push({ area: address.area, type: 'parent', name: address.area })
        }
        formattedAddressesOption.push({ ...address, type: 'child' })
      })
      return formattedAddressesOption
    }
    return []
  },

  randomColor() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    return `#${randomColor}`
  },
}
