import Vue from 'vue'
import axios from 'axios'
import UrlConfig from './config'

export const getToken = () => JSON.parse(localStorage.getItem('accessToken'))

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: new UrlConfig(window.location.host).getApiUrl(),
  // timeout: 1000,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
})

axiosIns.interceptors.request.use(config => config, error => console.log(error))
// Add a response interceptor
axiosIns.interceptors.response.use(response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('userData')
      window.location.reload()
    }
    return error.response
  })
Vue.prototype.$http = axiosIns
export default axiosIns
