import axios from '@axios'

export default {
  namespaced: true,
  state: {
    deliveries: [],
    order: null,
    isLoading: false,
  },
  getters: {
    order: state => state.order,
    isLoading: state => state.isLoading,
  },
  mutations: {
    SET_ORDERS_DATA_SUCCESS(state, response) {
      state.order = response.data
    },
    SET_FAILURE(state) {
      state.order = null
      state.deliveries = []
      state.isLoading = false
    },
    SET_IS_LOADING(state, value) {
      state.isLoading = value
    },
    SET_TO_EMPTY(state) {
      state.order = null
      state.deliveries = []
    },
    SET_PRODUCT_DETAILS(state, response) {
      state.items = [
        { label: 'Quantity', value: response.data.quantity },
        { label: 'Type of Packaging', value: response.data?.packaging?.text ?? '' },
        { label: `Size(${response.data.unit})`, value: response.data.size },
        { label: 'SKU', value: response.data.sku },
        { label: 'Expiry Date', value: response.data.po_expiry_date },
        { label: 'Remarks', value: response.data.remarks },
      ]
    },
    SET_DELIVERIES_SUCCESS(state, response) {
      state.deliveries = response
    },
  },
  actions: {
    async fetchDeliveries({ commit }, { deliveryId }) {
      try {
        commit('SET_TO_EMPTY')
        commit('SET_IS_LOADING', true)
        const response = await axios.get(
          `deliveries/${deliveryId}`,
        )
        commit('SET_DELIVERIES_SUCCESS', response.data)
        commit('SET_IS_LOADING', false)
        return response
      } catch (e) {
        commit('SET_FAILURE')
        throw new Error('Something went wrong.', e)
      }
    },
    async fetchOrder({ commit }, { orderId }) {
      try {
        commit('SET_TO_EMPTY')
        commit('SET_IS_LOADING', true)
        const response = await axios.get(
          `orders/${orderId}`,
        )
        if (response.status === 200) {
          commit('SET_ORDERS_DATA_SUCCESS', response.data)
          commit('SET_PRODUCT_DETAILS', response.data)
        }
        commit('SET_IS_LOADING', false)
        return response
      } catch (e) {
        commit('SET_FAILURE')
        throw new Error('Something went wrong.', e)
      }
    },
  },
}
